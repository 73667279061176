import React from "react";

const CallCenterIcon = () => {
  return (
    <svg
      width="41px"
      height="46px"
      viewBox="0 0 41 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="call-center-agent">
        <path
          d="M28.5178 29.7375L25.9667 29.7375L25.9667 28.0315C28.4433 26.3153 30.0667 23.4678 30.0667 20.2511L30.0667 17.5407L35.5333 17.5407L35.5333 14.8303C34.7052 -4.95107 6.2874 -4.9358 5.46667 14.8303L5.46667 22.9615L11.3319 22.9615C11.9572 25.0378 13.2816 26.8177 15.0333 28.0315L15.0333 29.7375L12.4822 29.7375C5.59951 29.7375 0 35.2901 0 42.1151L0 46L41 46L41 42.1151C41 35.2901 35.4005 29.7375 28.5178 29.7375ZM20.5 2.63347C27.2822 2.63347 32.8 8.10488 32.8 14.8303L30.0667 14.8303C29.5396 2.24217 11.4556 2.25193 10.9333 14.8303L8.2 14.8303C8.2 8.10497 13.7178 2.63347 20.5 2.63347ZM8.2 20.2511L8.2 17.5407L10.9333 17.5407L10.9333 20.2511L8.2 20.2511ZM13.6667 20.2511L13.6667 14.8303C14.0431 5.8388 26.9602 5.84575 27.3333 14.8303L27.3333 20.2511C27.2157 27.6216 17.244 29.5888 14.2389 22.9615L20.5 22.9615L20.5 20.2511L13.6667 20.2511ZM20.5 29.7376C21.4495 29.7376 22.3669 29.599 23.2333 29.3424L23.2333 29.7376C23.0827 33.3342 17.9159 33.3314 17.7667 29.7376L17.7667 29.3424C18.6331 29.5991 19.5505 29.7376 20.5 29.7376ZM2.73333 42.1151C2.73333 36.7846 7.10667 32.448 12.4822 32.448L15.7688 32.448C16.4109 33.5459 17.4295 34.4014 18.6491 34.8379L15.4529 43.2896L9.56667 43.2896L9.56667 39.224L6.83333 39.224L6.83333 43.2896L2.73333 43.2896L2.73333 42.1151ZM20.5 37.6628L22.6279 43.2896L18.3721 43.2896L20.5 37.6628ZM38.2667 43.2896L34.1667 43.2896L34.1667 39.224L31.4333 39.224L31.4333 43.2896L25.5471 43.2896L22.3509 34.8379C23.5705 34.4014 24.5891 33.5459 25.2312 32.448L28.5178 32.448C33.8933 32.448 38.2667 36.7846 38.2667 42.1151L38.2667 43.2896Z"
          id="Shape"
          fill="currentColor"
          stroke="none"
        />
      </g>
    </svg>
  );
};

export default CallCenterIcon;
